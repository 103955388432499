html,
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	background-color: #e6e6e6;
}

.container {
	margin: 80px auto 0 auto;
	max-width: 1200px;
}

/* @media only screen and (max-width: 780px) {
	.container {
		margin-left: -7px;
		margin-right: -7px;
	}
} */

.nav-container {
	color: #757575;
	background-color: #fff;
	width: 100%;
	display: flex;
	justify-content: center;
}

.nav {
	background-color: #fff;
}

a {
	text-decoration: none;
}

.test {
	background-color: #e1b5ff;
}
